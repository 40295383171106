export default class CustumerSupplier {
    constructor() {
        this.id = "";
        this.isLead = false;
        this.personRequest = { typePerson: 2, companyName: "", tradeName: "", document: "", stateInscription: "", municipalInscription: "" };
        this.categoryCustomer = { id: "", content: "" };
        this.contactRequest = { phone: "", webSite: "", email: "" };
        this.addressValueObject = {
            zipCode: "",
            street: "",
            number: "",
            complement: "",
            province: "",
            city: "",
            state: { id: "", content: "" },
        };
        this.billingEmail = [];
        this.billingMobile = [];
        this.franchiseBillet = 0;
        this.franchiseNf = 0;
        this.franchiseSignature = 0;
        this.observation = "";
    }
    update(data) {
        this.id = data.id;
        this.isLead = data.isLead;
        this.categoryCustomer = data.categoryCustomer;
        this.personRequest = data.person;
        this.contactRequest = data.contact;
        this.addressValueObject = data.address;

        if (data.billingEmail) {
            var emailsArray = data.billingEmail.trim().split(',');

            emailsArray = emailsArray.filter(function (email) {
                return email.trim() !== '';
            });

            this.billingEmail = emailsArray;
        }

        if (data.billingMobile) {
            var mobileArray = data.billingMobile.trim().split(',');

            mobileArray = mobileArray.filter(function (email) {
                return email.trim() !== '';
            });

            this.billingMobile = mobileArray;
        }

        this.franchiseBillet = data.franchiseBillet;
        this.franchiseNf = data.franchiseNf;
        this.franchiseSignature = data.franchiseSignature;
        this.observation = data.observation;
    }
}