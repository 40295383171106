<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList" :showSearch="false" :showHorizontalFilter="false"
      :propsParam="propsParam">
      <div slot="content-buttons">
        <Button _key="createContact" title="Contato" classIcon="fas fa-plus-circle" type="primary" size="small"
          eventName="createContact" :clicked="createContact" />
      </div>
    </ViewTemplateConfiguration>
    <Modal title="Contato" :width="750" :height="550" v-show="showModal('createUpdateContactCustomerSupplier')">
      <ContactCreateUpdate :customerSupplierId="customerSupplierId" />
    </Modal>
  </div>
</template>

<script>
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import ContactCreateUpdate from "./ContactCreateUpdate.vue";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ContactList",
  components: {
    ViewTemplateConfiguration,
    Button,
    Modal,
    ContactCreateUpdate,
  },
  props: {
    customerSupplierId: String,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/shared/contact/get-all",
        urlDeleteAllApi: "/api/v1/shared/contact/delete",
        headerTable: [
          {
            field: "name",
            title: "Nome",
            type: "link",
            eventName: "updateContactCustomerSupplier",
          },
          {
            field: "departament",
            title: "Departamento",
            type: "text",
          },
          {
            field: "birthDate",
            title: "Data Nascimento",
            type: "text",
          },
          {
            field: "mobile",
            title: "Celular",
            type: "text",
          },
          {
            field: "email",
            title: "E-mail",
            type: "text",
          },
        ],
      },
      propsParam: {
        any: this.customerSupplierId,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "removeLoading"]),
    createContact() {
      this.openModal("createUpdateContactCustomerSupplier");
      this.removeLoading(["createContact"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "updateContactCustomerSupplier")
          this.openModal("createUpdateContactCustomerSupplier");
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.g-div-moldura {
  border: 0px solid #dbdfe9 !important;
  padding: 0px !important;
}
</style>
