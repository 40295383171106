<template>
  <div class="margin">
    <div>
      <RadioGroup field="channel" :options="[
        { text: 'Lead', value: true },
        { text: 'Cliente', value: false },
      ]" v-model="customerSupplier.isLead" />
    </div>
    <Molded>
      <Person :showDocument="!customerSupplier.isLead" :formName="formName" :markFormDirty="true"
        v-model="customerSupplier.personRequest" />
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
          <Select title="Categoria" field="category" :formName="formName" :required="true" :markFormDirty="true"
            url="/api/v1/crm/category-customer/select-all" v-model="customerSupplier.categoryCustomer"
            nameNewRegister="categoryCustomerCreateUpdate" titleNewRegister="Categoria Cliente" :widthNewRegister="500"
            :heightNewRegister="250">
            <CategoryCustomerCreateUpdate :registerInSelect="true" v-model="customerSupplier.categoryCustomer" />
          </Select>
        </b-col>
      </b-row>
    </Molded>
    <div v-if="!customerSupplier.isLead">
      <br>
      <Molded>
        <AddressValueObject :formName="formName" :required="false" :markFormDirty="true"
          v-model="customerSupplier.addressValueObject" />
      </Molded>
      <br>
      <Molded>
        <Contact :formName="formName" :markFormDirty="false" v-model="customerSupplier.contactRequest" />
      </Molded>
    </div>
    <br>
    <b-tabs v-if="id">
      <b-tab title="Contato">
        <ContactList :customerSupplierId="id" />
      </b-tab>
      <b-tab title="Notificação de Cobrança" v-if="!customerSupplier.isLead">
        <InputTag title="E-mail" placeholder="Digite um e-mail e pressione Enter"
          :initialValue="customerSupplier.billingEmail" v-model="customerSupplier.billingEmail" :change="update" />
        <br />
        <InputTag title="WhatssApp" placeholder="Digite um número e pressione Enter"
          :initialValue="customerSupplier.billingMobile" v-model="customerSupplier.billingMobile" :change="update" />
      </b-tab>
      <b-tab title="Franquia">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="2" xl="2">
            <InputNumber title="Assinatura Digital" field="user" :formName="formName" :maxLength="50"
              v-model="customerSupplier.franchiseSignature" />
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="2" xl="2">
            <InputNumber title="Boleto Bancário" field="user" :formName="formName" :maxLength="50"
              v-model="customerSupplier.franchiseBillet" />
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="2" xl="2">
            <InputNumber title="Nota Fiscal" field="user" :formName="formName" :maxLength="50"
              v-model="customerSupplier.franchiseNf" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Observações">
        <TextArea title="Observações" field="observation" :required="false" :maxLength="800"
          v-model="customerSupplier.observation" :markFormDirty="false" />
      </b-tab>
    </b-tabs>
    <br />
  </div>
</template>

<script>

import TextArea from "@nixweb/nixloc-ui/src/component/forms/TextArea";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import InputTag from "@nixweb/nixloc-ui/src/component/forms/InputTag";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";

import CategoryCustomerCreateUpdate from "../../crm/category-customer/CategoryCustomerCreateUpdate.vue";
import ContactList from '../contact/ContactList.vue'

import Contact from "@nixweb/nixloc-ui/src/component/value-objects/Contact.vue";
import Person from "@nixweb/nixloc-ui/src/component/value-objects/Person.vue";
import AddressValueObject from "@nixweb/nixloc-ui/src/component/value-objects/Address.vue";

import CustomerSupplier from "@/components/modules/shared/customer-supplier/CustomerSupplier.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "CustomerSupplierCreateUpdate",
  props: {
    isLead: Boolean
  },
  components: {
    InputText,
    InputNumber,
    Select,
    TextArea,
    Person,
    Contact,
    Button,
    Molded,
    InputTag,
    CategoryCustomerCreateUpdate,
    AddressValueObject, ContactList, RadioGroup
  },
  data() {
    return {
      id: this.$route.params.id,
      formName: "customerSupplierCreate",
      customerSupplier: new CustomerSupplier(),
      urlCreate: "/api/v1/crm/customer/create",
      urlUpdate: "/api/v1/crm/customer/update",
      urlGetById: "/api/v1/crm/customer/get-by-id",
    };
  },
  created() {
    if (this.id) this.getById();
    this.customerSupplier.isLead = this.isLead;
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", ["removeLoading", "hideVodal", "hideSideBar"]),
    create() {
      let params = { url: this.urlCreate, obj: this.createObject() };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.$router.replace({
            name: this.customerSupplier.isLead ? "leadUpdate" : "customerUpdate",
            params: { id: response.content.id },
          });
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    update() {
      let params = { url: this.urlUpdate, obj: this.createObject() };
      this.putApi(params).then(() => {
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.id } };
      this.getApi(params).then((response) => {
        this.customerSupplier.update(this.updateObject(response.content));
        let self = this;
        setTimeout(function () {
          self.removeLoading(["panel"]);
        }, 100);
      });
    },
    createObject() {
      var obj = {
        id: this.id,
        isLead: this.customerSupplier.isLead,
        typePerson: this.customerSupplier.personRequest.typePerson,
        companyName: this.customerSupplier.personRequest.companyName,
        tradeName: this.customerSupplier.personRequest.tradeName,
        document: this.customerSupplier.personRequest.document,
        stateInscription: this.customerSupplier.personRequest.stateInscription,
        municipalInscription: this.customerSupplier.personRequest.municipalInscription,
        categoryCustomer: this.customerSupplier.categoryCustomer,
        phone: this.customerSupplier.contactRequest.phone,
        webSite: this.customerSupplier.contactRequest.webSite,
        email: this.customerSupplier.contactRequest.email,
        zipCode: this.customerSupplier.addressValueObject.zipCode,
        street: this.customerSupplier.addressValueObject.street,
        number: this.customerSupplier.addressValueObject.number,
        complement: this.customerSupplier.addressValueObject.complement,
        province: this.customerSupplier.addressValueObject.province,
        city: this.customerSupplier.addressValueObject.city,
        state: this.customerSupplier.addressValueObject.state,
        billingEmail: this.customerSupplier.billingEmail,
        billingMobile: this.customerSupplier.billingMobile,
        franchiseBillet: this.customerSupplier.franchiseBillet,
        franchiseNf: this.customerSupplier.franchiseNf,
        franchiseSignature: this.customerSupplier.franchiseSignature,
        observation: this.customerSupplier.observation,
      };
      return obj;
    },
    updateObject(data) {
      var obj = {
        id: data.id,
        isLead: data.isLead,
        person:
        {
          typePerson: data.person.typePerson,
          companyName: data.person.companyName,
          tradeName: data.person.tradeName,
          document: data.person.document ? data.person.document : "",
          stateInscription: data.person.stateInscription ? data.person.stateInscription : "",
          municipalInscription: data.person.municipalInscription ? data.person.municipalInscription : "",
        },
        categoryCustomer:
        {
          id: data.categoryCustomer ? data.categoryCustomer.id : "",
          content: data.categoryCustomer ? data.categoryCustomer.name : ""
        },
        contact:
        {
          phone: data.contact ? data.contact.phone : "",
          webSite: data.contact ? data.contact.webSite : "",
          email: data.contact ? data.contact.email : "",
        },
        address: {
          zipCode: data.address ? data.address.zipCode : "",
          street: data.address ? data.address.street : "",
          number: data.address ? data.address.number : "",
          complement: data.address ? data.address.complement : "",
          province: data.address ? data.address.province : "",
          city: data.address ? data.address.city : "",
          state: {
            id: data.address ? data.address.state : "",
            content: data.address ? data.address.state : "",
          },
        },
        billingEmail: data.billingEmail,
        billingMobile: data.billingMobile,
        franchiseBillet: data.franchiseBillet,
        franchiseNf: data.franchiseNf,
        franchiseSignature: data.franchiseSignature,
        observation: data.observation,
      };
      return obj;
    }
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.margin {
  margin-right: 10px;
}
</style>
