export default class Contact {
    constructor() {
        this.id = "";
        this.name = "";
        this.departament = "";
        this.mobile = "";
        this.email = "";
        this.birthDate = "";
        this.customerSupplierId = "";
    }
    update(data) {
        this.id = data.id;
        this.name = data.name;
        this.departament = data.departament;
        this.mobile = data.mobile;
        this.email = data.email;
        this.birthDate = data.birthDate;   
        this.customerSupplierId = data.customerSupplierId;
    }
}